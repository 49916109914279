import './App.css';
import Chatbot from './components/Chatbot';
import PageNotFound from './helper/PageNotFound';
import ScrollToTop from './helper/ScrollToTop';
import { Route, Routes, useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const slug = location.pathname.split("/")[1];
  return (
    <ScrollToTop>
    <div className=''>
      <div className='h-screen chat_area'>
        <Routes>
          <Route path="/" element={<Chatbot />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
    </ScrollToTop>
  );
}

export default App;
